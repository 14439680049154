<template>
  <div id="modals-container">
    
<!--  중요 디버깅 mUTP0zeAWTyDHX57PMZe  -->
<!--  modals = <span v-html="JSON.stringify(modals, null, 2).replaceAll('\n', '<br/>')"/>-->
    
    <modal
      v-for="modal in modals"
      :key="modal.id"
      v-bind="modal.modalAttrs"
      v-on="modal.modalListeners"
      @closed="remove(modal.id)"
      adaptive resizable
    >

<!--      -- 중요 디버깅 mUTP0zeAWTyDHX57PMZe  -->
<!--      (컨테이너)-->
<!--      modal.modalAttrs = {{ modal.modalAttrs }}-->
<!--      modal.modalListeners = {{ modal.modalListeners }}-->
      
      <component
        :is="modal.component"
        v-bind="{...modal.componentAttrs, ...$attrs}"
        @close="$modal.hide(modal.modalAttrs.name, $event)"
      >
<!--        v-on="$listeners"-->
        
        <template v-for="(slot, key) in modal.componentSlots" #[key]="scope" :key="key">
          <VNode :node="slot" :scope="scope" />
        </template>
      </component>
    </modal>
  </div>
</template>
<script>
import { generateId } from '../utils'
import VNode from './VNode.vue'

const PREFIX = 'dynamic_modal_'

export default {
  data() {
    return {
      modals: []
    }
  },
  components: {
    VNode
  },
  created() {
    /**
     * Register ModalContainer so that it was availiable inside the plugin
     */
    this.$root.__modalContainer = this
    this.$modal.subscription.$emit('set-modal-container', this)
  },
  mounted() {
    this.$modal.subscription.$on('hide-all', () => {
      this.modals = []
    })
  },
  methods: {
    add(
      component,
      componentAttrs = {},
      componentSlots = {},
      modalAttrs = {},
      modalListeners = {}
    ) {
      const id = generateId()
      const name = modalAttrs.name || PREFIX + id

      // 조금 덜중요 디버깅 VqHOFciJNoaL08tLC7sF
      // console.log("MC - 0 component = " + JSON.stringify(component, null, 2))
      // console.log("MC - 1 componentAttrs = " + JSON.stringify(componentAttrs, null, 2))
      // console.log("MC - 2 componentSlots = " + JSON.stringify(componentSlots, null, 2))
      // console.log("MC - 3 modalAttrs = " + JSON.stringify(modalAttrs, null, 2))
      // console.log("MC - 4 modalListeners str = " + JSON.stringify(modalListeners, null, 2))
      // console.log("PC - 4 modalListeners obj = ", modalListeners)
      

      this.modals.push({
        id,
        modalAttrs: { ...modalAttrs, name },
        modalListeners,
        component,
        componentAttrs,
        componentSlots
      })

      this.$nextTick(() => {
        this.$modal.show(name)
      })
    },
    remove(id) {
      const index = this.modals.findIndex(v => v.id === id)

      if (index !== -1) {
        this.modals.splice(index, 1)
      }
    }
  }
}
</script>
