
// 사용법은 하단에

export interface MenuType {
  depth      ?: number //  0
  idx        ?: number //  0
  link       ?: string //  "/lawyer/lawyerinfo_mgr"
  location   ?: string //  "변호사 정보 관리"
  name       ?: string //  "변호사 정보 관리"
  role       ?: string //  "12"
  parentName ?: string // "샘플"
  parentidx  ?: number // 19
  children   ?: MenuType[]
}


export type TreeTextPraserCB = (newItem: MenuType, parentItem: MenuType | undefined, parentsTree: MenuType[]) => void

export interface TreeTextPraseOptionType {
  /* 본문               */ menuSource     : string
  /* 순번 key 명        */ key_id         ?: string
  /* 서브 목록 key 명   */ key_sub        ?: string
  /* 깊이 key 명        */ key_depth      ?: string
  /* depth 공백 사이즈  */ depthSpaceSize ?: number

  /* 부모 참조 변수 설정 콜백 함수 */
  funcParentSet ?: TreeTextPraserCB
  /* 모든 속성설정 콜백 함수 (depth 가 0일땐 부모가 null 이다) */
  funcAttrSet   ?: TreeTextPraserCB
}

const defaultFuncParentSet: TreeTextPraserCB = (item, parent) => {
  item.parentName = parent?.name // 첫번째 컬럼이름이 name 이여야 한다
  item.parentidx  = parent?.idx  // key_id 가 idx 이여야 한다.
}

const defaultFuncAttrSet: TreeTextPraserCB = (item, parent, parentsTree) => {
  item.location = _.range((item.depth ?? 0)+1)   // key_depth 가 'depth' 여야 한다
    .map(i => parentsTree[i].name) // 첫번째 컬럼이름이 name 이여야 한다.
    .join(' > ')
}

/**
 * 트리구조로 된 text 를 파싱한다.
 */
export const treeTextParse = (
  {
    menuSource,
    key_id         = 'idx',
    key_sub        = 'children',
    key_depth      = 'depth',
    depthSpaceSize = 2,
    funcParentSet  = defaultFuncParentSet,
    funcAttrSet    = defaultFuncAttrSet,
  }: TreeTextPraseOptionType) : {treeList: MenuType[], itemList: MenuType[]} => {

  const lines = menuSource.split("\n") // 전체 라인 배열
  let   lineIndex = 0                            // 그 라인의 작업 index

  const treeList: MenuType[] = []     // 결과 저장소 (tree 타입)
  const itemList: MenuType[] = []     // 결과 저장소 (index 타입)
  const parentsTree: MenuType[] = [] // 작업용 부모 트리 -- 부모의 sub_list에 추가할때 필요

  // 첫줄은 header 라인. 첫줄을 찾아서 key_naems 를 지정한다. ex) "name | link | mode" -> ['name', 'link', 'mode']
  while(T.isBlank(lines[lineIndex])) lineIndex++ // 공백라인 패쓰
  const header = lines[lineIndex].trim().split(/\s*\|\s*/) // 딜리미터(|)로 split 한다

  let id = 0 // 순차증가 id 번호
  for(lineIndex++; lineIndex < lines.length; lineIndex++) {
    const line = lines[lineIndex]
    if (T.isBlank(line)) continue // 공백라인 패쓰

    // STEP 01 -- 신규 메뉴아이템 생성
    const newItem:MenuType = { [key_id]: id++ }
    itemList.push(newItem)

    // STEP 02 -- 구분자(|)로 split하여 속성들을 설정한다. key 이름은 위의 header 를 이용한다.
    line.split('|').forEach((str, idx) =>  // 속성들 설정
        !T.isBlank(str) && (newItem[header[idx]] = str.trim()))

    // STEP 03 -- 트리구조 처리
    // STEP 03-01 -- 깊이 계산 ( 앞의 공백수 이용 )
    const match = new RegExp(/^(\s*)/).exec(line) // 앞 공백만 뽑아서
    if (match === null) throw Error("match 결과가 없습니다. mefyg7VlrtHZi6Yx73Sr")
    const depth = match[0].length / depthSpaceSize // 공백으로 깊이 계산
    newItem[key_depth] = depth // 깊이 속성 셋팅

    // STEP 03-02 -- 작업용 부모트리 업데이트 ( 내가 언제라도 부모가 될 수 있기 때문에 무조건 현재 풀로 설정하고 본다 )
    parentsTree[depth] = newItem

    let parentItem: MenuType | undefined // 부모 아이템 임시 변수

    // STEP 03-03 -- depth 별 작업
    if (depth === 0) { // 루트 depth (0) 조치
      // 루트 item은 menuList에 추가한다 ( 루트 아이템만 menuList에 들어갈 수 있다. 나머지는 그것의 sub_list 로 추가한다 )
      treeList.push(newItem)
    } else { // 서브 depth (>0) 조치
      // 서브 item의 경우 부모를 찾아서 그 부모의 sub_list에 나를 추가한다.
      parentItem = parentsTree[depth-1] // 부모를 찾아서
      if (T.isNU(parentItem[key_sub])) parentItem[key_sub] = [] // 부모에 sub_list가 없으면 만들고
      parentItem[key_sub].push(newItem) // 부모의 서브 목록에 나를 추가한다.

      if (typeof(funcParentSet) === 'function') // 부모속성 콜백함수가 있으면 호출한다.
        funcParentSet(newItem, parentItem, parentsTree)
    }

    if (typeof(funcAttrSet) === 'function') // (모든)속성 설정 콜백함수가 있으면 호출한다.
      funcAttrSet(newItem, parentItem, parentsTree)
  }
  return {treeList, itemList}
}


/*
사용법

const text = `
name        | link
메뉴1a      | aa
메뉴1b      | bb
  메뉴2a    | cc
  메뉴2b    | dd
    메뉴3a  | ee
    메뉴3b  | ff
`

const {treeList} = treeTextParse({ menuSource: text })
console.log("treeList : ", JSON.stringify(treeList, null, 2))

(결과)
treeList :  [
  {
    "idx": 0,
    "name": "메뉴1a",
    "link": "aa",
    "depth": 0,
    "location": "메뉴1a"
  },
  {
    "idx": 1,
    "name": "메뉴1b",
    "link": "bb",
    "depth": 0,
    "location": "메뉴1b",
    "children": [
      {
        "idx": 2,
        "name": "메뉴2a",
        "link": "cc",
        "depth": 1,
        "parentName": "메뉴1b",
        "parentidx": 1,
        "location": "메뉴1b > 메뉴2a"
      },
      {
        "idx": 3,
        "name": "메뉴2b",
        "link": "dd",
        "depth": 1,
        "parentName": "메뉴1b",
        "parentidx": 1,
        "location": "메뉴1b > 메뉴2b",
        "children": [
          {
            "idx": 4,
            "name": "메뉴3a",
            "link": "ee",
            "depth": 2,
            "parentName": "메뉴2b",
            "parentidx": 3,
            "location": "메뉴1b > 메뉴2b > 메뉴3a"
          },
          {
            "idx": 5,
            "name": "메뉴3b",
            "link": "ff",
            "depth": 2,
            "parentName": "메뉴2b",
            "parentidx": 3,
            "location": "메뉴1b > 메뉴2b > 메뉴3b"
          }
        ]
      }
    ]
  }
]
 */

