/**
 * array 를 다루는 함수들
 */

import T from './index'

export default {

  /**
   * 문자열을 지정길이 단위로 지정횟수 만큼 자른다.<pre>
   * T.splitSize('1234567890', 3, 3) === ['123', '456', '789']
   * @param {string} str
   * @param {number} size
   * @param {number} cnt
   * @returns {string[]}
   */
  splitSize: (str, size, cnt) => T.isEmptyAny(str, size, cnt) ? []
    : _.range(cnt).map(i => str.substr(i * size, size)),

  isEmptyArray: array => !Array.isArray(array) || array.length < 1,
  isNotEmptyArray: array => Array.isArray(array) && array.length > 0,

  /**
   * 배열을 추가한다.<pre>
   * 첫번째 배열에 전부 push 한다
   * T.arrayAppend([1,2],[3,4],[5,6]) === [1,2,3,4,5,6]
   * </pre>
   * @param {Array} array
   * @param {Array} addArrays
   * @returns {Array} push된 첫번재 배열
   */
  arrayAppend: (array, ...addArrays) => {
    addArrays.forEach(ar => ar.forEach(a => array.push(a)))
    return array
  },


  findIndexByKey: (array, key, value) => { // 해당 리스트에서 원하는 값이 있는 row의 index 를 찾는다.
    if (!array) return -1
    const isEmptyValue = T.isEmpty(value) // value가 empty인가 ?
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (typeof item === 'object') {
        const v = item[key];
        if (isEmptyValue && T.isEmpty(v) || v === value)
          return i;
      }
    }
    return -1;
  },

  findArrayItem: (array, key, value) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] == value)
        return array[i]
    }
    return null
  },

  removeListFindByKey: (array, key, value) => { // 해당 리스트에서 원하는 값이있는 키를 찾아서 삭제한다.. 성공시 true
    const idx = T.findIndexByKey(array, key, value);
    if (idx < 0) return false; // 찾는키가 없으면 아무것도 안한다.
    array.splice(idx, 1);
    return true;
  },


  sortArrayMap: (array, key, isNumber = true) => {
    if (T.isEmptyArray(array)) return []
    return array.sort((a,b) => {
      const va = a[key]
      const vb = b[key]
      if (isNumber) {
        const na = T.toNumber(va) ?? 0
        const nb = T.toNumber(vb) ?? 0
        return na - nb
      } else {
        return va === vb ? 0 : va > vb ? 1 : -1
      }
    })
  }

}

