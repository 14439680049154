// import { getCurrentInstance } from 'vue'
import Modal from './components/Modal.vue'
import Dialog from './components/Dialog.vue'
import PluginCore from './PluginCore'
// import {getRootEL} from "@/rootHolder.js";

// let cnt = 0;
// let qqq = 0;

const Plugin = {
  install(app, options = {}) {

    // 이코드는 의미는 없어 보인다. 두번 호출되는 상황을 가정한거 같은데
    // vue3 에서는 app.use(..) 하면 동일한 inst 일때 알아서 무시해 준다.
    // 그래도 혹시 모르니 그냥 두자
    if (app.config.globalProperties.$modal) {
      console.log("[Plugin.js] app.config.globalProperties.$modal -- 이미 정의되어 있음. return")
      return
    }

    // console.log("[Plugin.js] 설정 시작")

    const plugin = PluginCore(app, options)

    // app.config.globalProperties.$modal = {
    //   get: function() {
    //     return plugin
    //   }
    // }

    // console.log("[Plugin.js] PluginCore 생성됨 =", plugin)
    // console.log("[Plugin.js] PluginCore this.에 설치  (this.$modal)")
    Object.defineProperty(app.config.globalProperties, '$modal', {
      get: function() {
        // ++cnt;
        // console.log(`[Plugin.js - get()] qqq = ${qqq++}, cnt = ${cnt}`);
        // console.log(`[Plugin.js - get()] app.config.globalProperties = `, app.config.globalProperties);
        // console.log(`[Plugin.js - get()] plugin.context.root = `, plugin.context.root);
        // if (cnt-- < 0) {
        //     return null;
        // }
        /**
         * The "this" scope is the scope of the component that calls this.$modal
         */
        // const caller = this;
        // console.log("2 plugin = ", plugin);
        // console.log("[Plugin.js - get()] 호출자 확인 caller =", caller);
        // console.log("[Plugin.js - get()] 호출자 확인 caller.proxy =", caller.proxy );
        // console.log("[Plugin.js - get()] 호출자 확인 caller?.$parent =", caller?.$parent );
        // console.log("[Plugin.js - get()] 호출자 확인 caller.proxy.$parent =", caller.proxy?.$parent );
        
        // console.log(cnt + " 1 [Plugin.js - get()] plugin.context.root =", plugin.context.root);
        // console.log("4 caller.$modal = ", caller?.$modal);
        /**
         * The this.$modal can be called only from inside the vue components so this check is not really needed...
         */
        // if (caller instanceof app) {
        //   const root = caller.$root
        // console.log("5 root = ", root);
          if (!plugin.context.root) {
            
            // const root = getRootEL()
            // const root = app._instance || app._container?._vnode?.component
            // const root = options.root
            const root = app
            // console.log("root = ", root);
            // console.log("app = ", app);
            // console.log("app._instance = ", app._instance);
            // console.log("app._context.root = ", app._context.root);
            // console.log("app.component() = ", app.component(0));
            // console.log("app._container?._vnode?.component = ", app._container?._vnode?.component);
            if (root) {
              // console.log("app = ", app);
              // console.log("app._instance = ", app._instance);
              // console.log("root = ", root);
              // plugin.setDynamicModalContainer(root, app)
              plugin.setDynamicModalContainer(root, app)
            }
            
            // console.log(cnt + " 2 [Plugin.js - get()] root =", root);
          }
        // }

        return plugin
      }
    })
    
    app.provide('$modal', plugin)

    /**
     * Sets custom component name (if provided)
     */

    // console.log("[Plugin.js] 글로벌 Modal.vue 컴포넌트 설치 : comp name =", plugin.context.componentName)
    app.component(plugin.context.componentName, Modal)

    /**
     * Registration of <Dialog/> component
     */
    // console.log("[Plugin.js] options.dialog 옵션 여부 =", options.dialog)
    if (options.dialog) {
      const componentName = options.dialogComponentName || 'VDialog';
      // console.log("[Plugin.js] 글로벌 Dialog.vue 컴포넌트로 설치 : comp name =", componentName)
      app.component(componentName, Dialog);
    } else {
      // console.log("[Plugin.js] 글로벌 Dialog.vue 컴포넌트는 설치 안함.")
    }
  }
}

export default Plugin
