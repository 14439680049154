/**
 * vue 전용
 */
import T from './index'

export default {

  /**
   * vue class 두개를 합친다. string map null 등등 고려함<pre>
   *   T.mergeClass("a b", {c:true, d:false})     === "a b c"
   *   T.mergeClass({a: true}, {b:true, c:false}) === {a: true, b:true, c:false}
   *   T.mergeClass({c:true, d:false}, "a b")     === "c a b"
   *   </pre>
   * @param {string|{}} class1
   * @param {string|{}} class2
   * @returns {string|{}}
   */
  mergeClass: (class1, class2) => {
    const type1 = typeof class1 === 'string' ? 'string' : _.isPlainObject(class1) ? 'map' : 'null'
    const type2 = typeof class2 === 'string' ? 'string' : _.isPlainObject(class2) ? 'map' : 'null'
    if (type2 === 'null') return type1 === 'null' ? "" : class1
    if (type1 === 'null') return class2
    switch(type1 + '_' + type2){
      case 'string_string': return class1 + ' ' + class2
      case 'string_map'   : return class1 + ' ' + classMapToString(class2)
      case 'map_string'   : return classMapToString(class1) + ' ' + class2
      case 'map_map'      : return {...class1, ...class2}
    }
    function classMapToString(map) {
      return Object.entries(map).filter(([,v])=>v).map(([k])=>k).join(" ")
    }
  },

}
