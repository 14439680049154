import {treeTextParse} from "@/lib/lib_treeTextParse";
import {RouteRecordRaw} from "vue-router";
import {setEnvFunction} from "@/lib/env/useLogin.ts";
// 자동변환 : mgr*/display/display -> pages/display/display

// link 에 해당하는 route 는 반드시 routes.ts 에 정의되어야 한다.
const menuSource = `
name                            | link
메인                            | /admin/main
사용자 관리                   | /admin/system/user_mgr

시스템
  코드관리                      | /admin/system/code
  전시관리                      | /admin/system/display?location_code=main_top_banner
`

// 메인2                           | /admin/main2?aa=bb
//
//   로그인                          | /login/login.wt
// 전시관리                        | /mgr_admin_2/display/display.wt
// 갤러리관리                      | /mgr_com/group/media.wt

const routesAdmin: RouteRecordRaw[] = [
  {path: '/admin/main'           , component: () => import('@/pages_admin/main.vue')},
  {path: '/admin/main2'          , component: () => import('@/pages_admin/main.vue')},
  {path: '/admin/system/code'    , component: () => import('@/pages_admin/system/code/code.vue')},
  {path: '/admin/system/display' , component: () => import('@/pages_admin/system/display/display.vue')},
  {path: '/admin/system/user_mgr', component: () => import('@/pages_admin/system/user_mgr.vue')},
]

// 모든 요청에 env 를 주입한다. - 즉, 페이지 이동시마다 env 를 조회한다. 덤으로 세션 정보도 업데이트 된다.
// routesAdmin.forEach(item => !item.beforeEnter && (item.beforeEnter = setEnvFunction))


export const { treeList: menuTreeSrc, itemList: menuItems } =
  treeTextParse({
    menuSource: menuSource})

export const routeAdmin: RouteRecordRaw = {
  path: '/admin', component: () => import('@/layouts/AdminLayout.vue'),
  children: routesAdmin
}

