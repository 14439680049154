import {useSessionStore} from "@/lib/env/useSessionStore.ts";


// 로그인 된 상태에서 api 응답을 받은 경우 세션 시간을 갱신한다.

// const log = T.getLogger("axios-interceptor.ts", "debug")

export const setAxiosForEnv = () => {
  const session = useSessionStore()

  // 모든 axios 응답에 대해서
  axios.interceptors.response.use(
    res => {

      // 현재 로그인 되어이 있고. api 요청이라면
      if (session.isLogin && res.config.url?.startsWith("/")) { // 로그인 되었는 경우에만
        // log.debug("use(): res.config.url = " + res.config.baseURL)
        // log.debug("use(): res.config.url = " + res.config.url)
        // log.debug("session.loginInfo?.user?.id = " + session.loginInfo?.user?.id)
        session.accessedSession() // 마지막 세션 억세스 시간 갱신
      }

      // if (!res.data) console.log(res.data);
      // if (res && res.headers && res.headers[_config.AUTH_TOKEN_NAME]) {
      //   const token = res.headers[_config.AUTH_TOKEN_NAME]
      //   store.state.session.sessionDebug && console.log("[SESSION] main.js axios.interceptors.response.RES - 수신 : 새로운 인증토큰 발급 받음 : token = ", token);
      //   store.commit("session/SET_TOKEN", token)
      // }
      return res
    },
    // err => {
    //   // 인증오류가 발생할 경우 로그인 페이지로 이동한다.
    //   // console.log(err.response.status);
    //   if (err.response.status === 401) {
    //     const tokenError = err.response && err.response.headers && err.response.headers['token-error'] || ''
    //     store.state.session.sessionDebug && console.log("[SESSION] main.js axios.interceptors.response.ERR - 수신 : 401 인증오류!! 로그인 페이지로 이동, token error = ", tokenError, "err.response = ", err.response);
    //     // this.alert("서버 오류가 발생했습니다[5]\n" + tokenError, "오류", { color: "red"})
    //     // alert("인증오류가 발생했습니다.\n" + tokenError)
    //     // const path = err.response && err.response.data && err.response.data.path
    //     const path = store && store.state && store.state.route && store.state.route.path || ''
    //     if (tokenError == "EXPIRED" && path != _config.LOGIN_PATH) {
    //       console.log("[세션만료]\n\n장시간 이용을 하지 않아 자동으로 로그아웃 되었습니다.");
    //     } else {
    //       alert("[JWT] 004 인증오류! 로그인으로 이동합니다.");
    //     }
    //     router.push(_config.LOGIN_PATH)
    //     // location.href = '/emp/#/login';
    //     return
    //   }
    //   return Promise.reject(err)
    // }
  )  
}