import {defineStore} from 'pinia'
import {EnvType, EnvTypeExt, LoginInfo} from "@/lib/env/type-env";
import {getNoLoginUserInfo, useEnvExt} from "@/lib/env/useLogin.ts";

export interface SessionState {
  loginInfoLastGet : number
  loginInfo        : LoginInfo
  envLastGet       : number
  env              : EnvTypeExt
}

// localStorage.removeItem('get_session_time') // 첫 로딩시 삭제

/**
 * 세션 스토어
 * - 로그인 정보말 글로벌 하게 보관한다.
 * - 일부 포멧 정도만 추가한다.
 */
export const useSessionStore = defineStore(
  'session', { // https://pinia.vuejs.kr/core-concepts/state.html#TypeScript
  state: (): SessionState => ({
    loginInfoLastGet : 0,
    loginInfo        : {login: false, user: getNoLoginUserInfo()},
    envLastGet       : 0,
    env              : useEnvExt(),
  }),
  getters: {
    isLogin: (state) => state.loginInfo?.login ?? false,
    dp: (state) => {
      const user = state.loginInfo?.user
      return user ? `${user.name}(${user.id})` : '로그인 안됨'
    },
  },
  actions: {
    // 로그인 정보 설정
    setLoginInfo(loginInfo: LoginInfo) {
      this.loginInfo = loginInfo
      // this.loginInfoLastGet = 0
      this.loginInfoLastGet = new Date().getTime()
      this.accessedSession();
      return loginInfo;
    },
    
    // 세션 억세스 시간 갱신
    accessedSession() {
      this.loginInfo.get_session_time = new Date().getTime()
      localStorage.setItem('get_session_time', '' + this.loginInfo.get_session_time);
    },

    // 세션 억세스 시간 가져오기 ( 다른창에서 갱신한 경우를 고려하여 로컬 스토리지 확인 )
    _getGetSessionTime() {
      const {get_session_time} = this.loginInfo ?? {} // 변수값 조회
      const l_get_session_time = localStorage.getItem('get_session_time'); // 로컬 스토리지 조회
      
      if (l_get_session_time) { // 로컬 스토리지에 값이 있는데
        const n_get_session_time = Number(l_get_session_time)
        if (!get_session_time || n_get_session_time > get_session_time) { // 그게 변수값 보다 크다면 다른 창에서 갱신한 경우이다.
          this.loginInfo.get_session_time = n_get_session_time // 그럼 내 변수값도 그 값으로 갱신하고 반환
          return n_get_session_time
        }
      }
      return get_session_time // 그 외의 경우에는 그냥 변수값 사용
    },
    
    // env 정보 설정 - 로그인 정보가 포함되어 있을 것이기에 로그인 정보도 추가로 갱신한다.
    setEnv(envInfo: EnvType) {
      this.env = useEnvExt(envInfo)
      this.envLastGet = new Date().getTime()
      this.setLoginInfo({
        login            : envInfo.login,
        user             : envInfo.user,
        max_session_time : envInfo.max_session_time,
      })
      return envInfo
    },

    // 로그인 실패 설정
    setNotLogin() {
      this.setLoginInfo({login: false, user: getNoLoginUserInfo()})
      if (this.env !== null) {
        // 이 부분은 조금 애매하다. 로그아웃시 env 정보를 갱신해야 할까 ?
        this.env.login = false
        this.env.user = getNoLoginUserInfo()
        this.env.max_session_time = undefined
      }
    },

    // 세션 남은시간: -1 이면 만료. 0이상이면 남은시간(초)
    leftSessionSecond() {
      const {max_session_time} = this.loginInfo ?? {}
      const get_session_time = this._getGetSessionTime()
      if (!get_session_time || !max_session_time) return -1;
      
      const pastSec = Math.round((new Date().getTime() - get_session_time) / 1000) // 경과시간
      // console.log(`경과 시간 = ${pastSec} 초, 총 시간 = ${max_session_time}, 남은시간 = ${leftSec} `)
      return max_session_time - pastSec // 남은시간 sec
    },
    
  },
})

