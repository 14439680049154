import { Quasar } from 'quasar'
import quasarLang from 'quasar/lang/ko-KR'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import {App} from "vue";

// quasar 설정 - https://quasar.dev/start/vite-plugin

// export default async ({ app }: { app: App }) => {
//   app.use(Quasar, {
//     plugins: {}, // import Quasar plugins and add here
//     lang: quasarLang,
//   })
// }


export default {
  install(app: App) {
    app.use(Quasar, {
      plugins: {}, // import Quasar plugins and add here
      lang: quasarLang,
    })
  },
};


