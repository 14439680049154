import { UNSUPPORTED_ARGUMENT_ERROR } from './utils/errors'
import { createDivInBody } from './utils'
import ModalsContainer from './components/ModalsContainer.vue'
import emitter from 'tiny-emitter/instance'
import {createVNode, render} from 'vue'

const PluginCore = (app, options = {}) => {
  const subscription = {
    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args),
  }

  const context = {
    root: null,
    componentName: options.componentName || 'Modal'
  }
  subscription.$on('set-modal-container', container => {
    context.root.__modalContainer = container
  })

  const showStaticModal = (name, params) => {
    subscription.$emit('toggle', name, true, params)
  }

  const showDynamicModal = (
    component,
    componentProps,
    componentSlots,
    modalProps = {},
    modalEvents
  ) => {
    const container = context.root?.__modalContainer
    const defaults = options.dynamicDefaults || {}

    // 조금 덜중요 디버깅 VqHOFciJNoaL08tLC7sF
    // console.log("PC - 0 component = " + JSON.stringify(component, null, 2))
    // console.log("PC - 1 componentProps = " + JSON.stringify(componentProps, null, 2))
    // console.log("PC - 2 componentSlots = " + JSON.stringify(componentSlots, null, 2))
    // console.log("PC - - defaults = " + JSON.stringify(defaults, null, 2))
    // console.log("PC - 3 modalProps = " + JSON.stringify(modalProps, null, 2))
    // console.log("PC - 4 modalEvents str = " + JSON.stringify(modalEvents, null, 2))
    // console.log("PC - 4 modalEvents obj = ", modalEvents)

    container?.add(
      component,
      componentProps, 
        {} /*componentSlots*/,
      { ...defaults, ...modalProps },
      modalEvents
    )
  }

  /**
   * Creates a container for modals in the root Vue component.
   *
   * @param {Vue} parent
   * @param {Vue} app
   */
  const setDynamicModalContainer = ((parent, app) => {
    console.log("[PluginCore.js] setDynamicModalContainer() 호출됨 - 런타임에 document.body 에 ModalsContainer.vue 를 렌더링함")
    context.root = parent

    const element = createDivInBody() // 그냥 빈 <div/> 만들고 document.body 에 추가함

    const vnode = createVNode(ModalsContainer) // ModalsContainer.vue 컴포넌트를 VNode 로 생성
    vnode.appContext = app._context // 이건 뭐하는건지 모르겠음. 흐름상. ModalsContainer.vue 와 app ( Main vue instance ) 를 연결하는 것으로 보임
    render(vnode, element) // document.body 에 추가한 <div/> 에 ModalsContainer.vue 를 렌더링함
  })

  const show = (...args) => {
    const [modal] = args
    // console.log("1 [PluginCore.js show()] args =", args)
    // console.log("1 [PluginCore.js show()] args =", JSON.stringify(args, null, 2))
    // console.log("2 [PluginCore.js show()] modal =", modal)
    // console.log("3 [PluginCore.js show()] typeof modal =", typeof modal)

    switch (typeof modal) {
      case 'string':
        showStaticModal(...args)
        break

      case 'object':
      case 'function':
        showDynamicModal(...args)
        break

      default:
        console.warn(UNSUPPORTED_ARGUMENT_ERROR, modal)
    }
  }

  const hide = (name, params) => {
    subscription.$emit('toggle', name, false, params)
  }

  const hideAll = () => {
    subscription.$emit('hide-all')
  }

  const toggle = (name, params) => {
    subscription.$emit('toggle', name, undefined, params)
  }

  return {
    context,
    subscription,
    show,
    hide,
    hideAll,
    toggle,
    setDynamicModalContainer
  }
}

export default PluginCore
