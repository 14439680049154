/**
 * s-table 용 T 함수들
 */

import T from './index'
import {MapType} from "./tools_types";

const convertHeaderDefault = v =>
  v === undefined   ? undefined :
  v === "undefined" ? undefined :
  v === "true"      ? true      :
  v === "false"     ? false     :
  v;

export default {

  /*
    멀티라인을 json객체로 변경  /n 으로 끝나면 숫자변환
    makeJson(`
      text  | value  | seq/n
      번호  | a      | 1
      제목  | b      | 2
      작성일| c      | 3
    `, '|');
  */
  makeJson: <RowType>(data, delimiter = "|"): RowType[] => {
    const delimiter_regex = new RegExp('\\s*\\' + delimiter + '\\s*'); // delimiter 정규식
    const ar = data.trim().split(/\s*\n+\s*/); // newLine 기준 배열로 변환
    const keyNaemsAr = ar.shift().split(delimiter_regex) // 첫줄은 names 처리

    //----------------------------------------------------------------
    // 첫줄... 헤더 파싱
    //----------------------------------------------------------------
    const headers:MapType = [];
    keyNaemsAr.forEach(h => {
      const header = {key: ar[0]} as any
      if (h.includes('=')) { // align=center | nowrp = nowrap |  형태의 기본값 처리
        const ar = h.split(/\s*=\s*/)
        header.key     = ar[0]
        header.default = convertHeaderDefault(ar[1])
      }
      else if (h.includes('/')) { // width/n | max_files/n |  형태의 숫자 타입 지정
        const ar = h.split(/\s*\/\s*/)
        header.key = ar[0]
        const v = ar[1]
        if (v === 'n') header.convertNumber = true // with/n 형식이면 숫자변환 필요 옵션 설정
      }
      else {
        header.key = h
      }
      headers.push(header)
    })

    //----------------------------------------------------------------
    // 두번째 줄의 (default) 형태의 기본값 처리
    // (default)    |             |         | center
    //----------------------------------------------------------------
    if (ar[0].indexOf('(default)') >= 0) {
      const values = ar.shift().split(delimiter_regex) // 첫줄은 names 처리
      headers.forEach((header, i) => {
        // const k = header.key
        const v = values[i]
        if (v !== undefined)
          header.default = convertHeaderDefault(v)
      })
    }

    const ret: RowType[] = []; // 결과
    ar.forEach(line => {
      const values = line.split(delimiter_regex) // delimiter 로 요소 나누기
      const newItem = {}
      headers.forEach((header, i) => {
        const k = header.key
        const d = header.default
        const isConvertNumber = header.convertNumber
        const v = values[i]
        const ret = convertHeaderDefault(v)
        //        /^\d+$/.test(v) ? parseInt(v) :

        const result_v = (d !== undefined && T.isEmpty(ret)) ? d : ret
        if (isConvertNumber) {
          newItem[k] = T.toNumber(result_v)
        } else {
          newItem[k] = result_v
        }

      }) // key 기준으로 무조건 설정 ( 값이 없어도 속성은 무조건 만든다. - 중요!! )
      ret.push(newItem as RowType);
    });

    return ret;
  },


  /*
    샘플데이터 만들기
    T.makeSample("a,b,c,d", 10),
    T.makeSample("a|b|c|d".split("|"), 10),
  */
  makeSample: (keys: string | string[], count: number): MapType[] => {
    if (typeof keys === 'string' && keys.indexOf(',') !== -1) {
      keys = keys.split(',');
    }

    const ret: MapType[] = [];
    for (let i = 0; i < count; i++) {
      const item: MapType = {};
      for (const key of keys) {
        item[key] = ("" + Math.random()).substring(2, 10);
      }
      ret.push(item);
    }
    return ret;
  },


  /**
    샘플데이터 만들기
    T.makeSample("a,b,c,d", 10),
    T.makeSample("a|b|c|d".split("|"), 10),
  */
  makeSampleTable: (keys: string | string[], count: number): any => {
    const ret: any = {
      headers: [],
      items: [],
      page: {}
    };

    // key가 문자열로 들어온 경우 알아서 배열로 split
    if (typeof keys === "string" && keys.indexOf(',') !== -1) {
      keys = keys.split(',');
    }

    // 해더 만들기
    const headers: MapType[] = [];
    (keys as string[]).forEach((v, i) => {
      headers.push({
        text: v,
        value: 'k' + i
      });
    });
    ret.headers = headers;

    // 샘플 데이터 만들기
    const items: MapType[] = [];
    for (let i = 1; i < count; i++) {
      const item: MapType = {};
      for (const j in (keys as string[])) {
        item['k' + j] = ("" + Math.random()).substring(2, 10);
      }
      items.push(item);
    }
    ret.items = items;

    // 페이지네이션
    ret.page = {};

    return ret;
  },

  /**
   * string 을 string array 로 변환한다.
   * @param str 문자열 또는 str 배열
   * @param delimiter 문자열일경우 delimiter 로 나눈다
   * @param defaultValue str 타입이 문자열도 배열도 아닐경우
   */
  stringToArray: (str: string | string[] | any, delimiter = ",", defaultValue = null) =>
    typeof (str) === 'string' ? str.trim().split(new RegExp("\\s*" + delimiter + "\\s*")) // 문자열일때
      : _.isArray(str) ? str // 이미 배열일땐 그대로 사용
        : defaultValue // 그 외에는 null

}
