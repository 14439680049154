import {defineStore} from 'pinia'
import {RouteLocationRaw} from "vue-router";

// export const useSessionStore
//   = defineStore('session', () => {
//  
//   const status = ref<LoginStatus>('notLogin')
//   const info = ref<UserInfo | null>(null)
//  
//   const loginStatus = ref<LoginResponse | null>(null)
//  
//   return {
//     status, info, loginStatus
//   }
// })


export const useGlobalRouter = defineStore('globalRouter', {

  actions: {
    async push(to: RouteLocationRaw) {
      // @ts-ignore
      await this.router.push(to)
    }
  },
})
