/**
 * form의 input box 이동용
 */

import T from './index'

export default {

  nextFocus: e => T.NF(T.getNextInput(e.target), v => v.focus()),
  prevFocus: e => T.NF(T.getNextInput(e.target, true), v => v.focus()),
  nextSelect: e => T.NF(T.getNextInput(e.target), v => v.select()),
  prevSelect: e => T.NF(T.getNextInput(e.target, true), v => v.select()),

  getNextInput: (e, isPrev: boolean | null = null) => {
    if (T.isNU(e)) return null // 입력값 체크
    const obj = isPrev ? e.previousElementSibling : e.nextElementSibling; // 다음꺼 (또는 이전꺼)
    if (obj) {
      const ret = getInputElement(obj, isPrev)
      if (ret != null) return ret // 다음꺼에 input 있으면 return
      return T.getNextInput(obj, isPrev) // 없으면 그 다음꺼
    }

    // 없으면 위로가서 다음꺼
    return T.getNextInput(e.parentElement, isPrev)

    function getInputElement(e, isPrev) { // element 안에 유효한 input 이 있는경우 return
      if (T.isNU(e)) return null // 입력값 체크
      if (e.tagName === 'INPUT') {
        if (e.disabled === true) return null
        return e
      }
      const arr = e.getElementsByTagName("input")
      if (arr == null || arr.length === 0) return null  // 아무것도 없으면 null

      if (arr.length === 1) return arr[0].disabled === true ? null : arr[0] // input이 하나라면 return

      console.log("arr.length= ", arr.length);
      if (isPrev === true) { // 꺼구로 disabled가 아닌거 탐색
        for (let idx = arr.length - 1; idx >= 0; idx--) {
          if (arr[idx].disabled === true) continue
          return arr[idx]
        }
      } else { // 앞으로 disabled가 아닌거 탐색
        for (let idx = 0; idx < arr.length; idx++) {
          if (arr[idx].disabled === true) continue
          return arr[idx]
        }
      }
      return null
    }
  },
}
